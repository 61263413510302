import Head from 'next/head'
import { LoginPage } from '@upper/auth'

export default function Login() {
  return (
    <>
      <Head>
        <title>Login - Upper</title>
      </Head>

      <LoginPage noBackToUpper noCredentials />
    </>
  )
}

Login.redirectAuthenticatedTo = '/'
